import { DocumentType, PaymentType } from '@innedit/innedit-type';
import classnames from 'classnames';
import { PaiementData } from 'packages/innedit';
import React, { FC, useEffect, useState } from 'react';

import Tag, { TagStateProps } from '~/components/Tag';
import { ListItemProps } from '~/containers/Espace/List/props';

import Item from './index';

const labelPaymentStatus = (paymentStatus?: string): string | undefined => {
  let statusLabel;
  switch (paymentStatus) {
    case 'canceled': {
      statusLabel = 'Paiement annulé';
      break;
    }

    case 'partial_refunded': {
      statusLabel = 'Paiement partiellement remboursé';
      break;
    }

    case 'refunded': {
      statusLabel = 'Paiement remboursé';
      break;
    }

    case 'requires_capture': {
      statusLabel = 'En attente de validation';
      break;
    }

    case 'requires_payment_method': {
      statusLabel = "En attente d'un moyen de paiement";
      break;
    }

    case 'succeeded': {
      statusLabel = 'Paiement validé';
      break;
    }

    default:
      statusLabel = paymentStatus;
  }

  return statusLabel;
};

const statePaymentStatus = (paymentStatus?: string): TagStateProps => {
  let state: TagStateProps;
  switch (paymentStatus) {
    case 'canceled': {
      state = 'info';
      break;
    }

    case 'partial_refunded': {
      state = 'warning';
      break;
    }

    case 'refunded': {
      state = 'info';
      break;
    }

    case 'requires_capture': {
      state = 'danger';
      break;
    }

    case 'requires_payment_method': {
      state = 'warning';
      break;
    }

    case 'succeeded': {
      state = 'success';
      break;
    }

    default:
      state = 'standard';
  }

  return state;
};

const ListItemPaiement: FC<ListItemProps<PaymentType, PaiementData>> = ({
  docId,
  index,
  onClick,
  model,
}) => {
  const [doc, setDoc] = useState<DocumentType<PaymentType>>();

  useEffect(() => {
    const unsub = model.watchById(docId, document => {
      setDoc(document);
    });

    return () => {
      if (unsub) {
        unsub();
      }
    };
  }, [docId, model]);

  if (!doc) {
    return null;
  }

  return (
    <Item
      date={doc.createdAt}
      doc={doc}
      index={index}
      label={doc.label}
      onClick={onClick}
    >
      <div className="flex flex-1 items-center space-x-6">
        <div className="flex-1">
          <Tag state={statePaymentStatus(doc.status)}>
            {labelPaymentStatus(doc.status)}
          </Tag>
        </div>

        <div className="flex space-x-2">
          {(undefined === doc.amountReceived || 0 === doc.amountReceived) && (
            <span>{doc.amount} €</span>
          )}
          {undefined !== doc.amountRefunded && doc.amountRefunded > 0 && (
            <span>{doc.amountReceived - doc.amountRefunded} €</span>
          )}
          {undefined !== doc.amountReceived && doc.amountReceived > 0 && (
            <span
              className={classnames({
                'line-through':
                  undefined !== doc.amountRefunded && doc.amountRefunded > 0,
              })}
            >
              {doc.amountReceived} €
            </span>
          )}
        </div>
      </div>
    </Item>
  );
};

export default ListItemPaiement;
